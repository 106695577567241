.customDropdown {
	padding: 12px;
	gap: 18px;
	width: 258px;

	&:before, &:after {
		display: none !important;
	}

	background: rgba(18, 59, 94, 0.9) !important;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25) !important;
	backdrop-filter: blur(8px) !important;

	border-radius: 8px !important;
}
