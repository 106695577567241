@use './mixins/breakpoint';
@import 'variables';
@import 'fonts';
@import 'extensions';
@import 'components';

::placeholder {
	@include roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: $gray-400;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: $gray-400 $gray-200;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: $gray-200;
}

*::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: 12px;
    border: 3px solid $gray-200;
}

.roboto {
	@include roboto;
}

.roboto-slab {
	@include roboto-slab;
}

.he {

	&-bg {
		&--primary {
			background: $primary-7;
		}
	}

	&-paragraph {

		@mixin base-paragraph($fontSize: 14px) {
			@include roboto;
			line-height: 130%;
			font-size: $fontSize;
			@content;
			&--underlined {
				@include roboto;
				line-height: 130%;
				font-size: $fontSize;
				@content;
				text-decoration-line: underline;
			}
			&--bold {
				@include roboto;
				line-height: 130%;
				font-size: $fontSize;
				@content;
				font-weight: 500 !important;
			}
			&--italic {
				@include roboto;
				line-height: 130%;
				font-size: $fontSize;
				@content;
				font-style: italic;
			}
		}

		&--xs {
			@include base-paragraph(10px) {
				font-weight: 400;
				.pi {
					font-size: 8px;
				}
			}
		}

		&--small {
			@include base-paragraph(12px) {
				.pi {
					font-size: 10px;
				}
				font-weight: 400;
			}
		}

		&--medium {
			@include base-paragraph {
				.pi {
					font-size: 12px;
				}
				font-weight: 500;
			};
		}

		&--regular {
			@include base-paragraph {
				.pi {
					font-size: 12px;
				}
				font-weight: 400;
			};
		}

		&--regular-16 {
			@include base-paragraph(16) {
				.pi {
					font-size: 14px;
				}
				font-weight: 400;
			};
		}
	}

	&-header {
		&--h1 {
			@include roboto-slab;
			font-weight: 500 !important;
			font-size: 32px !important;
			line-height: 130% !important;

			i.pi {
				font-size: 22px;
			}
		}

		&--h1-small {
			@include roboto-slab;
			font-style: normal;
			font-weight: 500 !important;
			font-size: 25px !important;
			line-height: 130% !important;
		}

		&--h2 {
			@include roboto-slab;
			font-weight: 500 !important;
			font-size: 21px !important;
			line-height: 130% !important;
		}

		&--h3 {
			@include roboto;
			font-weight: 700 !important;
			font-size: 18px !important;
			line-height: 130% !important;
		}
	}

	&-button {
		@mixin base-button {
			display: flex !important;
			flex-direction: row !important;
			justify-content: center !important;
			align-items: center !important;
			gap: 8px !important;
			border-radius: 8px !important;
		}

		@mixin icon-only {
			display: flex !important;
			justify-content: center !important;
			align-items: center !important;
			aspect-ratio: 1/1 !important;
		}

		@mixin xxs {
			@extend .he-paragraph--xs;
			padding: 4px 10px;
			height: 28px !important;
			i.pi {
				font-size: 8px;
			}
		}

		@mixin xs {
			@extend .he-paragraph--regular;
			padding: 6px 10px;
			height: 36px !important;
			i.pi {
				font-size: 11px;
			}
		}

		@mixin md {
			@extend .he-paragraph--medium;
			padding: 10px 14px;
			height: 40px !important;
			i.pi {
				font-size: 14px;
			}
		}

		@mixin lg {
			@extend .he-paragraph--medium;
			padding: 16px;
			height: 44px !important;
			i.pi {
				font-size: 14px;
			}
		}

		@mixin button-rounded($size) {
			&--rounded {
				@content;
				@if ($size == "xs") {
					border-radius: 20px !important;
					@include xs;
				} @else if ($size == "md") {
					border-radius: 28px !important;
					@include md;
				} @else {
					border-radius: 32px !important;
					@include lg;
				}
			}
		}

		@mixin no-fill($color, $darkerColor, $border: true, $hoverBgColor: $gray-50) {
			background: transparent;
			color: $color;
			@if ($border == true) {
				border: 1px solid $color;
			} @else {
				border: none;
			}
			&:hover {
				background: $hoverBgColor !important;
				color: $darkerColor !important;
				@if ($border == true) {
					border: 1px solid $darkerColor !important;
				} @else {
					border: none !important;
				}
			}
		}

		@mixin fill($color, $darkerColor) {
			background: $color;
			border: none !important;
			&:hover {
				background: $darkerColor !important;
			}
		}

		@mixin size {
			&--xxs {
				@content("xxs");
				@include xxs;
			}
			&--xs {
				@content("xs");
				@include xs;
			}
			&--md {
				@content("md");
				@include md;
			}
			&--lg {
				@content("lg");
				@include lg;
			}
		}

		@mixin derive($name, $color, $darkerColor) {
			&--#{$name} {
				@include size using ($size){
					@include base-button;
					@include fill($color, $darkerColor);
					@include button-rounded($size) {
						@include base-button;
						@include fill($color, $darkerColor);
					}
				}
			}

			&--#{$name}-nf {
				@include size using ($size) {
					@include base-button;
					@include no-fill($color, $darkerColor);
					@include button-rounded($size) {
						@include base-button;
						@include no-fill($color, $darkerColor);
					};
				}
			}

			&--#{$name}-nfb {
				@include size using ($size){
					@include base-button;
					@include no-fill($color, $darkerColor, false);
					@include button-rounded($size) {
						@include base-button;
						@include no-fill($color, $darkerColor, false);
					};
				}
			}
		}

		@include derive("primary", $primaryColor, $primary-700);
		@include derive("secondary", $secondary-100, $primary-900);
		@include derive("secondary-variant", $gray-500, $gray-600);
		@include derive("success", map-get($colors, "light-green"), map-get($colors, "green"));
		@include derive("info", map-get($colors, "light-blue"), map-get($colors, "blue"));
		@include derive("warning", map-get($colors, "light-orange"), map-get($colors, "orange"));
		@include derive("danger", map-get($colors, "light-red"), map-get($colors, "red"));

	}

}

.cursor-default {
	cursor: default;
}

.p-password.p-input-icon-left, .p-password.p-input-icon-right {
	i {
		cursor: pointer;
	}
}

.p-input-icon {
	&-left, &-right {
		&:before {
			content: "";
			background: $gray-200;
			position: absolute;
			top: 1px;
			height: 38px;
			width: 39px;
			box-sizing: border-box;
			transition: 0.2s linear all;
		}
		i {
			color: $gray-400 !important;
		}
	}

	&-left{
		.p-inputtext {
			padding-left: 48px;
		}
		&:before {
			left: 1px;
			border-radius: calc($borderRadius - 2px) 0 0 calc($borderRadius - 2px);
			border-right: 1px solid $gray-300;
		}
		i {
			left: 12px !important;
		}
	}

	&-right {
		.p-inputtext {
			padding-right: 48px;
		}

		&:before {
			right: 1px;
			border-radius: 0 calc($borderRadius - 1px) calc($borderRadius - 1px) 0;
			border-left: 1px solid $gray-300;
		}
		i {
			right: 12px !important;
		}
	}
}

.p-inputtext {
	border: 1px solid $gray-300;
	height: 40px;
	width: 100%;

	&[disabled] {
		background-color: $gray-100;
		color: $gray-400 !important;
	}

	&-invalid {
		input, input:hover, input:focus {
			border: 1px solid map-get($colors, 'red') !important;
		}
	}
}

.p-progressbar-value {
	background: map-get($colors, 'light-green') !important;
}

.p-overlaypanel-content {
	padding: 0 !important;
	width: 100%;
	height: 100%;
}

.p-avatar-circle {
	border-radius: 50% !important;
}

.quill-custom {
	.p-editor-content {
		border: none !important;
		.ql-editor {
			padding: 0;
			background: transparent !important;
			@include roboto(true);
			font-size: 16px;
			font-weight: 400;
			line-height: 150%;
			color: $gray-600;
		}
	}
}

.quill-custom-mobile {
	.p-editor-content {
		border: none !important;
		.ql-editor {
			padding: 0;
			background: transparent !important;
			@include roboto(true);
			font-size: 14px;
			font-weight: 400;
			line-height: 150%;
			color: $gray-600;
		}
	}
}

.p-galleria {
	overflow: hidden;
	&-thumbnail-item {
		overflow: hidden !important;
	}
}
