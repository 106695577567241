.skeleton {
	&-content {
		width: 1140px;
	}
	&-card {
		background: #E9ECEF;
		position: absolute !important;
		top: 0;
		transform: translateY(-50%);
		right: 0;
		z-index: 5;
	}
}
