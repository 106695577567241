$colors: (
		"blue": #2196F3,
		"green": #4caf50,
		"light-green": #2ECC71,
		"light-blue": #3498DB,
		"light-red": #E74C3C,
		"light-yellow": #F1C40F,
		"light-orange": #F39C12,
		"red": #e74c3c,
		"yellow": #FFEAB4,
		"yellowDark": #493E21,
		"cyan": #00BCD4,
		"pink": #ec4899,
		"indigo": #3F51B5,
		"teal": #009688,
		"orange": #F57C00,
		"bluegray": #607D8B,
		"purple": #9C27B0,
		"white": #fff
);

$gray: (
		"25": #FCFCFD,
		"50": #F9FAFB,
		"100": #F2F4F7,
		"200": #EAECF0,
		"300": #D0D5DD,
		"400": #98A2B3,
		"500":  #667085,
		"600": #475467,
		"700": #344054,
		"800": #1D2939,
		"900": #101828,
);

$red: (
		"7": #FDF5F5,
		"10": #FAEBEB,
		"25": #F2CFCF,
		"50": #E59999,
		"75": #D07373,
		"100": #C04D4D,
		"400": #9C3E3E,
		"600": #8C3737,
		"900": #3D1B1B,
);

$orange: (
		"7": #FEF6F2,
		"10": #FDECE5,
		"25": #FADBCB,
		"50": #F5B897,
		"75": #F09E6E,
		"100": #EB8545,
		"600": #A25F3A,
		"900": #5B2F1E,
);

$yellow: (
		"7": #FEF9F2,
		"10": #FEF3E5,
		"25": #FCE7CB,
		"50": #F8D297,
		"75": #F4C06E,
		"100": #F0A845,
		"600": #A66C3A,
		"900": #5E361E,
);

$green: (
		"7": #F4FAF4,
		"10": #EAF4EA,
		"25": #D2E7D2,
		"50": #A5D0A5,
		"75": #78B978,
		"100": #4B9D4B,
		"600": #2F6A2F,
		"900": #1C3F1C,
);

$primary: (
		"7": #F1F7FD,
		"10": #EBF4FC,
		"25": #CEE3F8,
		"50": #9DC7F1,
		"75": #6DACEA,
		"100": #3C90E3,
		"600": #0B5599,
		"900": #123B5E,
);

$secondary: (
		"7": #F1F7FD,
		"10": #E6EFF5,
		"25": #C3D4E4,
		"50": #85AACC,
		"75": #4881B3,
		"100": #0B5599,
);

@mixin colors($map, $prefix) {
	@each $key in map-keys($map) {

		.#{$prefix}-#{$key} {
			color: map-get($map, $key) !important;
		}

		.bg-#{$prefix}-#{$key} {
			background-color: map-get($map, $key) !important;
		}
	}

	:root {
		@each $key in map-keys($map) {
			--#{$prefix}-#{$key}: #{map-get($map, $key)};
		}

	}
}

@include colors($colors, "color");
@include colors($gray, "gray");
@include colors($primary, "primary");
@include colors($secondary, "secondary");
@include colors($red, "red");
@include colors($orange, "orange");
@include colors($yellow, "yellow");
@include colors($green, "green");

$gray-25: map-get($gray, "25");
$gray-50: map-get($gray, "50");
$gray-100: map-get($gray, "100");
$gray-200: map-get($gray, "200");
$gray-300: map-get($gray, "300");
$gray-400: map-get($gray, "400");
$gray-500: map-get($gray, "500");
$gray-600: map-get($gray, "600");
$gray-700: map-get($gray, "700");
$gray-800: map-get($gray, "800");
$gray-900: map-get($gray, "900");

$primary-7: #F1F7FD;
$primary-10: #EBF4FC;
$primary-25: #CEE3F8;
$primary-50: #9DC7F1;
$primary-75: #6DACEA;
$primary-100: #3C90E3;
$primary-700: #0B5599;
$primary-900: #123B5E;

$secondary-7: #F1F7FD;
$secondary-10: #E6EFF5;
$secondary-25: #C3D4E4;
$secondary-50: #85AACC;
$secondary-75: #4881B3;
$secondary-100: #0B5599;

// Theme Specific Variables
$primaryColor: $primary-100;
$primaryDarkColor: $primary-700;
$primaryDarkerColor: $primary-900;
$primaryTextColor: #ffffff;
$bg-secondary-color: #FFFFFF;
$bg-primary-color: #EFF3F8;
$text-neutral-black: #3F3F46;
$text-neutral-grey: #71717A;
$text-subtle-grey: #94949E;

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';



.primary-7 { color: $primary-7; }
.primary-1 { color: $primary-10; }
.primary-25 { color: $primary-25; }
.primary-50 { color: $primary-50; }
.primary-75 { color: $primary-75; }
.primary-100 { color: $primary-100; }
.primary-700 { color: $primary-700; }
.primary-900 { color: $primary-900; }

.secondary-7 { color: $secondary-7; }
.secondary-10 { color: $secondary-10; }
.secondary-25 { color: $secondary-25; }
.secondary-50 { color: $secondary-50; }
.secondary-75 { color: $secondary-75; }
.secondary-100 { color: $secondary-100; }

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#F2A6B3;
    --primary-color: $primaryColor;
    --primary-color-text:#ffffff;
    --bg-primary:$bg-primary-color;
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
}
