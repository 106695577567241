@use "src/utils/theme/theme" as *;

.avatar {
	@include roboto-slab(false);
	font-weight: 500;
	font-size: 12px;
	line-height: 130%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #493E21;
}

.progressbar {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 6px;
	grid-gap: 4px;
	grid-auto-columns: minmax(6px,1fr);
	grid-auto-flow: column;
}
